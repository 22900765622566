import React from 'react';

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src="https://cdn.pulsevettt.com/logos/hmt/logo_200x78.png"
      {...props}
      style={{width: "50%", height: "%0%", filter: "brightness(0) invert(1)"}}
    />
  );
};

export default Logo;
