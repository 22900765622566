import React, { useState } from 'react';
import { 
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    makeStyles,
    FormControlLabel,
    Switch
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { useRecoilCallback, useRecoilState } from 'recoil';
import { availableTrodeTypesAtom } from 'src/app-data/atoms/trodes-atom';
import { postToAPISelector } from 'src/app-data/triggers/api-triggers';
import { httpClient } from 'src/lib/api-factory';

import MessageBox from 'src/components/MessageBox';


const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        fontSize: "0.850rem"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    reset: {
        marginRight: theme.spacing(1)
    },
    actionLink: {
        fontSize: "0.875rem",
        color: "#3f51b5",
        padding: "0",
        textTransform: 'none'
    },
    inactiveWarning: {
        fontSize: "0.775rem",
        color: "#ff0000",
        width: "auto"
    }
}));

export const NewTrodeType = (props) => { 
    const classes = useStyles();
    const [displayMessage, updateDisplayMessage] = useState({
        message: "",
        show: false,
        severity: "", //error, warning, info, success
        showAction: false
    });
    const [_isSubmitting, updateIsSubmitting] = useState(false);
    const [allTrodeTypes, addNewItemToState] = useRecoilState(availableTrodeTypesAtom);
    const [typeToEdit, setTypeToEdit] = useState({});
    const [deactivated, setDeactivated] = useState(false);
    const [alert, setAlert] = useState({
        severity: "warning",
        title: "",
        message: "",
        open: false
    });

    const isUnique = useRecoilCallback(() => async (trodeTypeName) => { 
        try {
            const results = await httpClient().get(`/trode-type-by-name/${trodeTypeName}`);
            return results.data;
        }
        catch(error) {
            return error;
        }    
    });

    const postToAPI = useRecoilCallback(({snapshot}) => async (req) => {
        try {
            const results = await snapshot.getPromise(postToAPISelector(req));
            return results;
        }
        catch(error) {
            return error;
        }    
    });

    const refreshTrodeTypeList = async () => {
        const results = await httpClient().get(`/trode-types`);
        addNewItemToState(results.data);
    };

    const handleCleanup = (resetFunc) => {
        updateIsSubmitting(false);
        updateDisplayMessage({ message: "", show: false, severity: "" });
        
        if(typeof resetFunc === "function") {
            resetFunc();
        }
    };

    const handleEditOrCancel = (item, resetFunc) => {
        setTypeToEdit(item);
        setDeactivated(false);

        if(typeof resetFunc === "function") {
            resetFunc();
        }
    };

    const handleEditActivedChange = (item, value) => {
        setTypeToEdit({...item, isActive: value === 1 ? 0 : 1})
        setDeactivated(!deactivated);

        var which = value === 1 ? 0 : 1;

        if(which === 0) {
            setAlert({
                ...alert,
                open: true,
                title: "Important Message",
                message: "Once set to 'Inactive' and saved, the Trode Type will be removed. This cannot be undone. Press the Cancel button if you did not mean to do this.",
                severity: "warning"
            });
        }
    };

    const handleEditSave = (values, validateFunc, setTouched, resetFunc) => {
        const { edit_trodeTypeName, edit_isActive } = values;

        (async () => { 
            try {
                const valError = await validateFunc();
                var editErrors = false;

                Object.keys(valError).forEach(key => {
                    if(key.indexOf('edit_') !== -1) {
                        editErrors = true;

                        setTouched({
                            [key]: true
                        });
                    }
                }); 

                if(!editErrors) { 
                    var alterResponse = await postToAPI({ 
                        uri: `/trode-types/${typeToEdit.trodeTypeId}/alter`,
                        data: {
                            trodeTypeName: edit_trodeTypeName.trim(),
                            isActive: edit_isActive
                        }
                    });

                    if(alterResponse && alterResponse.trodeTypeId) {
                        await refreshTrodeTypeList();
                    }
                    else {
                        setAlert({
                            ...alert,
                            open: true,
                            title: "Error Message",
                            message: "There was an error and the system failed to make the update.",
                            severity: "error"
                        });
                    }

                    handleEditOrCancel({}, resetFunc);
                }
            }
            catch(error) {
                console.log(error);
                setAlert({
                    ...alert,
                    open: true,
                    title: "Error Message",
                    message: "There was an error and the system failed to make the update.",
                    severity: "error"
                });
            }
        })();
    };

    const handleSubmit = (values, validateFunc, setTouched, resetFunc) => {
        const { trodeTypeName } = values;

        (async () => {
            try {
                const valError = await validateFunc();
                var entryErrors = false;

                Object.keys(valError).forEach(key => {
                    if(key.indexOf('edit_') === -1) {
                        entryErrors = true;

                        setTouched({
                            [key]: true
                        });
                    }
                });

                if(entryErrors) {
                    updateIsSubmitting(false);
                }
                else {
                    updateIsSubmitting(true);

                    var foundItem = await isUnique(trodeTypeName.trim());

                    if(Object.keys(foundItem).length > 0) {
                        updateDisplayMessage({ 
                            message: "The Trode Name you entered is already in use. Please enter a new name.",
                            show: true,
                            severity: "warning", 
                            showAction: false 
                        });

                        updateIsSubmitting(false);
                    }
                    else {
                        updateIsSubmitting(true);

                        var addNewResponse = await postToAPI({ 
                            uri: `/trode-types`,
                            data: {
                                trodeTypeName: trodeTypeName.trim()
                            }
                        });

                        if(addNewResponse && addNewResponse.trodeTypeId) {
                            await refreshTrodeTypeList();

                            updateDisplayMessage({ 
                                message: "The new Trode Type has been created.",
                                show: true,
                                severity: "success",
                                showAction: false 
                            });
                    
                            if(typeof resetFunc === "function") {
                                resetFunc();
                            }
                        }
                        else {
                            updateDisplayMessage({ 
                                message: `There was an error creating the new Trode Type. Take note of the selections you've made and contact an administrator.`,
                                show: true,
                                severity: "error", 
                                showAction: false 
                            });
                        }
                    }
                }
            }
            catch(error) {
                updateDisplayMessage({ 
                    message: `There was a system error: ${error && error.message}`,
                    show: true,
                    severity: "error", 
                    showAction: false 
                });
            }
        })();
    };

    return (
        <>
            <Formik
                initialValues={{
                    trodeTypeName: '',
                    edit_trodeTypeName: typeToEdit.trodeTypeName || '',
                    edit_isActive: typeToEdit.isActive || 0
                }}
                validationSchema={Yup.object().shape({
                    trodeTypeName: Yup.string().max(25, 'Trode Type Name can be a max of 25 characters').required('Trode Type Name is required'),
                    edit_trodeTypeName: typeToEdit.trodeTypeName && Yup.string().max(25, 'Trode Type Name can be a max of 25 characters').required('Trode Type Name is required')
                })}
            >
                {({errors, handleBlur, handleChange, handleReset, validateForm, setTouched, touched, values, initialValues}) => (
                <>
                    <Card>
                        <CardHeader
                            subheader="All fields are required."
                            title="Create New Trode Type"
                        />

                        <Divider />

                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Trode Type Name"
                                        name="trodeTypeName"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        required
                                        type="text"
                                        SelectProps={{ native: true }}
                                        value={values.trodeTypeName || ''}
                                        variant="outlined"
                                        error={Boolean(touched.trodeTypeName && errors.trodeTypeName)}
                                        helperText={touched.trodeTypeName && errors.trodeTypeName}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>

                                {displayMessage.show &&
                                <Grid item xs={12}>
                                    <Alert severity={displayMessage.severity}>
                                        {displayMessage.message}
                                    </Alert>
                                </Grid>
                                }
                            </Grid>
                        </CardContent>

                        <Divider />

                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}
                        >
                            <Button 
                                color="primary" 
                                variant="contained"
                                onClick={() => handleCleanup(handleReset)} 
                                className={classes.reset}
                            >
                                Reset
                            </Button>

                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                onClick={() => handleSubmit(values, validateForm, setTouched, handleReset)}
                                disabled={_isSubmitting}
                            >
                                Submit
                            </Button>
                        </Box>

                        <Divider />

                        <PerfectScrollbar>
                            <Box>
                                <Table>
                                    <TableHead>
                                        <TableRow style={{backgroundColor: "#dedede"}}>
                                            <TableCell>Type name</TableCell>
                                            <TableCell style={{width: 300}}>Status</TableCell>
                                            <TableCell style={{width: 150}}>&nbsp;</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                    {allTrodeTypes && allTrodeTypes.length > 0 && allTrodeTypes.map((item) => {
                                        if(typeToEdit.trodeTypeId === item.trodeTypeId) {
                                            initialValues.edit_trodeTypeName = typeToEdit.trodeTypeName;
                                            initialValues.edit_isActive = typeToEdit.isActive;
                                            
                                            return (
                                                <TableRow hover key={item.trodeTypeId}>
                                                    <TableCell>
                                                        <TextField
                                                            fullWidth
                                                            label="Trode Type Name"
                                                            name="edit_trodeTypeName"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            required
                                                            type="text"
                                                            SelectProps={{ native: true }}
                                                            value={values.edit_trodeTypeName}
                                                            variant="outlined"
                                                            error={Boolean(touched.edit_trodeTypeName && errors.edit_trodeTypeName)}
                                                            helperText={touched.edit_trodeTypeName && errors.edit_trodeTypeName}
                                                            className={classes.textField}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                        />
                                                    </TableCell>

                                                    <TableCell>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch 
                                                                    size="small" 
                                                                    checked={values.edit_isActive === 1} 
                                                                    onChange={() => handleEditActivedChange(item, values.edit_isActive)} 
                                                                />
                                                            }
                                                            label={values.edit_isActive === 1 ? 'Active' : 'Inactive'}
                                                        />
                                                        { deactivated && (
                                                        <Alert severity="warning" className={classes.inactiveWarning} 
                                                            style={{width: "100%", padding: 1}}
                                                        >
                                                            Once set to 'Inactive' it will be removed. This cannot be undone.
                                                        </Alert>
                                                        )}
                                                    </TableCell>

                                                    <TableCell>
                                                        <Box display="flex" justifyContent="flex-end">
                                                            <Button
                                                                variant="contained"
                                                                className={classes.actionLink}
                                                                style={{marginRight: "10px"}}
                                                                onClick={() => handleEditOrCancel({}, handleReset)}
                                                            >
                                                                Cancel
                                                            </Button>

                                                            <Button
                                                                variant="contained"
                                                                className={classes.actionLink}
                                                                onClick={() => handleEditSave(values, validateForm, setTouched, handleReset)}
                                                            >
                                                                Save
                                                            </Button>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                        else {
                                            return (
                                                <TableRow hover key={item.trodeTypeId}>
                                                    <TableCell>
                                                        {item.trodeTypeName}
                                                    </TableCell>

                                                    <TableCell>
                                                        {item.isActive === 0 ? "InActive" : "Active"}
                                                    </TableCell>

                                                    <TableCell>
                                                        <Box display="flex" justifyContent="flex-end">
                                                            <Button
                                                                variant="contained"
                                                                className={classes.actionLink}
                                                                onClick={() => handleEditOrCancel(item)}
                                                            >
                                                                Edit
                                                            </Button>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    })}
                                    </TableBody>
                                </Table>
                            </Box>
                        </PerfectScrollbar>
                    </Card>
                </>
                )}
            </Formik>

            <MessageBox 
                key={`message_box`} 
                open={alert.open} 
                title={alert.title} 
                message={alert.message} 
                severity={alert.severity} 
                handleClose={() => setAlert({...alert, open: false})}
            />
        </>
    );
};
