import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';

import { formatDate } from 'src/lib/date-functions';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  scrollBox: {
    height: "500px"
  }
}));

const LatestOrders = ({ className, customers, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Last 20 Orders" />

      <Divider />

      <PerfectScrollbar>
        <Box minWidth={800} className={classes.scrollBox}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date Shipped</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Serial #</TableCell>
                <TableCell>Trode</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Location</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              { customers.length === 0 && (
                <TableRow>
                  <TableCell colSpan="7" style={{textAlign: "center"}}>
                    <CircularProgress /> Loading Orders...
                  </TableCell>
                </TableRow>
              )}

              {customers && customers.length > 0 && customers.map((order) => (
                <TableRow hover key={uuid()}>
                  <TableCell>
                    {formatDate(order.dateSentOut)}
                  </TableCell>

                  <TableCell>
                    {order.customerName}
                  </TableCell>

                  <TableCell>
                    {order.serialNumber}
                  </TableCell>

                  <TableCell>
                    {order.trodeTypeName}
                  </TableCell>

                  <TableCell>
                    {`${order.trodeTypeSize}${order.trodeTypeMeasurement}`}
                  </TableCell>

                  <TableCell>
                    { order.stateName ? `${order.stateName}, US` : order.countryName }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>

      <Box display="flex" justifyContent="flex-end" p={2}>
        <Typography
            color="textSecondary"
            variant="caption"
          >
            Last 20 Orders
          </Typography>
      </Box>
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string
};

export default LatestOrders;
