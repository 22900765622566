import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useRecoilValue, useRecoilState, useSetRecoilState, useRecoilCallback } from 'recoil';
import { getCustomersDisplayAtom, getCustomersFullAtom, customerSearchByNameKeyValueAtom } from 'src/app-data/atoms/customers-atom';
import { getUnitedStatesAtom, getCountriesAtom } from 'src/app-data/atoms/locations-atom';
import { httpClient } from 'src/lib/api-factory';


const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();

  const [customers, setCustomers] = useRecoilState(getCustomersFullAtom);
  const setCustomersDisplayList = useSetRecoilState(getCustomersDisplayAtom);
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);
  const setUnitedStates = useSetRecoilState(getUnitedStatesAtom); 
  const setCountries = useSetRecoilState(getCountriesAtom); 
  const [isSearching, setIsSearching] = useState(false);
  const customerSearchKey = useRecoilValue(customerSearchByNameKeyValueAtom);

  const getCustomers = useRecoilCallback(() => async () => { 
      try {
          const results = await httpClient().get("/customers?all=true");
          return results.data;
      }
      catch(error) {
          return [];
      }    
  });

  const getCustomerById = useRecoilCallback(() => async (id) => { 
    try {
        const results = await httpClient().get(`/customers/${id}/type/accountId`);
        return results.data;
    }
    catch(error) {
        return [];
    }    
  });

  const getUnitedStates = useRecoilCallback(() => async () => {
    try {
      const results = await httpClient().get(`/united-states`);
        return results.data;
    }
    catch(error) {
        return [];
    }
  });

  const getCountries = useRecoilCallback(() => async () => { 
    try {
        const results = await httpClient().get("/countries");
        var countries = [];

        if(results && results.data && results.data.length > 0) {
          results.data.forEach(item => {
            if(item.countryName === 'Japan') {
              countries.unshift(item);
            }
            else {
              countries.push(item);
            }
          });
          
          return countries;
        }
        else {
          return results.data;
        }
    }
    catch(error) {
        return [];
    }    
  }); 

  const getAllCustomersHandler = () => {
    setCustomersDisplayList(customers);
  };

  const searchCustomerByIdHandler = (id) => {
    (async () => {
      try {
        const singleCustomer = await getCustomerById(id);
        setCustomersDisplayList([singleCustomer]);
      }
      catch(error) {

      }

      setIsSearching(false);
    })();
  };

  const submitHandler = () => {
    if(selectedCustomerId !== 0) {
      setIsSearching(true);
      searchCustomerByIdHandler(selectedCustomerId);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        //Used by dropdown list as well as 'all customers' button since it already has the full list
        const customersResponse = await getCustomers(); 
        setCustomers(customersResponse);

        //These two get lists a single time here to be used in edit modals
        const unitedStatessResponse = await getUnitedStates();
        setUnitedStates(unitedStatessResponse);

        const countriesResponse = await getCountries();
        setCountries(countriesResponse);
        //end
      }
      catch(error) {

      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box display="flex" justifyContent="flex-end">
        <Button color="primary" variant="contained" onClick={getAllCustomersHandler}>
          Get All Customers
        </Button>
      </Box>

      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              { customers && customers.length > 0 ?
              <Autocomplete
                id="combo-box-customers"
                key={customerSearchKey}
                options={customers}
                fullWidth
                getOptionLabel={(option) => `${option.customerName} #${option.customerNumber}`}
                getOptionSelected={(option) => option.customerAccountId}
                onChange={(event, value) => value ? setSelectedCustomerId(value.customerAccountId) : setSelectedCustomerId(0)}
                renderOption={(option) => (
                  <React.Fragment>
                    <span style={{width: 60}}>#{option.customerNumber}</span>
                    {option.customerName}
                  </React.Fragment>
                  )} 
                  renderInput={
                    (params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Select Customer"
                        name="customerAccountId"
                        required
                        SelectProps={{ native: true }}
                        value={selectedCustomerId}
                        variant="outlined"
                        style={{maxWidth: 500}}
                      />
                    )
                  }
              />
              : 
              <Typography color="textPrimary" variant="body1" style={{fontSize: "0.850rem"}}>
                No customers added. Click on 'Add Customer' to create one.
              </Typography>
              }
            </Box>

            <Box maxWidth={500} style={{paddingTop: "10px"}}>
              <Grid container justify="flex-end">
                <Button 
                  color="primary" 
                  variant="contained" 
                  onClick={submitHandler}
                  disabled={isSearching}
                >
                  { isSearching ? 'Searching' : 'Search' }
                </Button>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
